
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import MuseDentalReception from '../images/muse-dental-reception.jpg';

const AppointmentCTA = () => {

    return (
        <section>
          <div className="relative bg-gray-100">
            <div className="h-56 bg-muse-yellow-dark sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
              <img className="w-full h-full object-cover" src={MuseDentalReception} alt=""/>
            </div>
            <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
              <div className="md:ml-auto md:w-1/2 md:pl-10">
                <p className="mt-2 text-muse-teal text-3xl font-extrabold tracking-tight sm:text-3xl">
                We're Looking Forward To Your Visit!
                </p>
                <p className="mt-3 text-lg text-gray-700">
                  An entirely modern dental experience awaits you at Muse Dental. If you have questions before booking your appointment, then <Link to="/contact-us" className="font-bold hover:text-muse-yellow">get in touch with us.</Link> 
                </p>
                <div className="mt-8">
                  <div className="inline-flex rounded-md shadow">
                    <button onClick={() => window.initFlexBook('https://flexbook.me/musedental')} className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-muse-yellow hover:bg-muse-teal hover:text-white">
                      Book Appointment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
}

export default AppointmentCTA;