import * as React from 'react';
import { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import DrBanitaMann from '../images/DrBanitaMann-Resize.jpg';
import DrSmitaKumar from '../images/DrSmitaKumar-Resize.jpg';
import AppointmentCTA from '../components/AppointmentCTA';

const Patients = () => {

    return(
        <Layout>
            <header>
            <div className="relative bg-muse-yellow">
                <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
                    <h1 className="text-4xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl text-center">For Patients</h1>
                </div>
                </div>
            </header>

            <section>
                
            </section>

        <AppointmentCTA/>
        </Layout>
    )
}

export default Patients;